
import React, { useState, useEffect } from 'react';

async function fetchData(channelId, field) {
  const url = `https://api.thingspeak.com/channels/${channelId}/fields/${field}.json?results=1`;
  const response = await fetch(url);
  const data = await response.json();
  return data;
}

const SensorStatus = ({title}) => {
    
    
    const [Count, setCount] = useState(0);

    useEffect(() => {
        const channels = [
            { channelId: 2449872, field: 1 },
            { channelId: 2449873, field: 1 },
            { channelId: 2449874, field: 1 },
            { channelId: 2449875, field: 1 },
            // Add more channels and fields as needed
        ];

        const thirtyMinutesAgo = Date.now() - 30 * 60 * 1000; // 30 minutes in milliseconds

        const countSensorStatus = async () => {
            let activeCount = 0;
            let offlineCount = 0;
            let warningCount = 0;

            for (const { channelId, field } of channels) {
                try {
                    const data = await fetchData(channelId, field);
                    if (data && data.feeds && data.feeds.length > 0) {
                        const entryTime = new Date(data.feeds[0].created_at).getTime();
                        if (entryTime >= thirtyMinutesAgo) {
                            activeCount++;
                            const lastValue = parseFloat(data.feeds[0][`field${field}`]);
                            if (!isNaN(lastValue) && lastValue > 30) {
                                warningCount++;
                            }
                        } else {
                            offlineCount++;
                        }
                    } else {
                        offlineCount++;
                    }
                } catch (error) {
                    console.error(`Error fetching data for channel ${channelId} field ${field}:`, error);
                    offlineCount++;
                }
            }
            let c =title
           if(c=== "Active"){
            console.log(c)
            setCount(activeCount);
           }
           if(c=== "Warnings"){
            console.log(c)
            setCount(warningCount);
           }
           if(c=== "Offline"){
            console.log(c)
            setCount(offlineCount);
           }
           
        };
        

        countSensorStatus();
    }, [title]); // Run once when component mounts

    return (
        <div>
           <p> {Count}</p>
            
        </div>
    );
};

export default SensorStatus;