import React, { useState, useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';
import axios from 'axios';
import { parseISO } from 'date-fns';

const LineChart = ({ channelNumber, fieldNumber }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.thingspeak.com/channels/${channelNumber}/fields/${fieldNumber}.json?results=50`
        );

        const fetchedData = response.data.feeds.map(feed => ({
          x: parseISO(feed.created_at),
          y: parseFloat(feed[`field${fieldNumber}`])
        }));

        // Ensure data is sorted by date
        fetchedData.sort((a, b) => new Date(a.x) - new Date(b.x));

        setData([
          {
            id: channelNumber.toString(),
            data: fetchedData
          }
        ]);

        setLoading(false);
        setError(null);
      } catch (error) {
        console.error('Error fetching data', error);
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 5000);

    return () => clearInterval(interval);
  }, [channelNumber, fieldNumber]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!data) return null; // or handle initial state when data is null

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: 'time', format: 'native', precision: 'minute' }}
      xFormat="time:%Y-%m-%d %H:%M:%S"
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Time',
        legendOffset: 36,
        legendPosition: 'middle',
        format: '%H:%M',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Value',
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      enableGridX={false}
      enableGridY={false}
      colors={{ scheme: 'nivo' }}
      lineWidth={2}
      enablePoints={true}
      enableArea={true}
      areaBaselineValue={15}
      areaOpacity={0.5}
      animate={true}
      motionConfig="wobbly"
      defs={[
        {
          id: 'gradientA',
          type: 'linearGradient',
          colors: [
            { offset: 0, color: 'inherit' }, // Start with the line color
            { offset: 0.78, color: 'rgba(0, 0, 0, 0)' }, // Fade to transparent
          ],
        },
      ]}
      fill={[
        { match: '*', id: 'gradientA' },
      ]}
    />
  );
};

export default LineChart;
