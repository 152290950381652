import React, { useState, useEffect  } from 'react';
import { Box, Typography, IconButton, Popover, Slider, useTheme } from '@mui/material';
import { tokens } from '../theme'; // Assuming you have a tokens function for theme colors
import WhatshotIcon from '@mui/icons-material/Whatshot'; // Hot icon
import WbSunnyIcon from '@mui/icons-material/WbSunny'; // Moderate icon
import AcUnitIcon from '@mui/icons-material/AcUnit'; // Cold icon
import PersonIcon from '@mui/icons-material/Person'; // Contact person icon
import MoreVertIcon from '@mui/icons-material/MoreVert'; // Three-dot icon
import DownloadIcon from '@mui/icons-material/Download'; // Download icon
import { motion } from 'framer-motion';
import axios from 'axios';
const TemperatureBox = ({sensorName, status }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [temperature, setTemperature] = useState(0); 
  const [anchorEl, setAnchorEl] = useState(null);
  const [highTemp, setHighTemp] = useState(35);
  const [lowTemp, setLowTemp] = useState(15);

  const fetchTemperature = async () => {
    try {
      const response = await axios.get('https://api.thingspeak.com/channels/2449875/fields/1.json?results=1');
      const data = response.data.feeds[0][`field${1}`];
      setTemperature(parseFloat(data));
    } catch (error) {
      console.error('Error fetching temperature data:', error);
    }
  };

  useEffect(() => {
    fetchTemperature();
    const interval = setInterval(fetchTemperature, 60000); // Fetch temperature every minute
    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHighTempChange = (event, newValue) => {
    setHighTemp(newValue);
  };

  const handleLowTempChange = (event, newValue) => {
    setLowTemp(newValue);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let TemperatureIcon;
  let background;
  if (temperature >= highTemp) {
    TemperatureIcon = WhatshotIcon;
    background = `linear-gradient(135deg, ${colors.redAccent[400]}, ${colors.redAccent[600]})`;
  } else if (temperature >= lowTemp) {
    TemperatureIcon = WbSunnyIcon;
    background = `linear-gradient(135deg, ${colors.primary[400]}, ${colors.primary[400]})`;
  } else {
    TemperatureIcon = AcUnitIcon;
    background = `linear-gradient(135deg, ${colors.blueAccent[400]}, ${colors.blueAccent[600]})`;
  }

  const dotColor = status === 'Active' ? '#4caf50' : '#f44336'; // Lighter green for active, red for offline

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: 'easeInOut' }}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: background,
        borderRadius: '33px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
       
        textAlign: 'center',
        color: '#ffffff',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
        }}
      >
        <IconButton size="small" sx={{ color: '#ffffff' }} onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={2}>
          <Typography variant="h6">Adjust Temperatures</Typography>
          <Typography>High Temperature</Typography>
          <Slider
            value={highTemp}
            onChange={handleHighTempChange}
            aria-labelledby="high-temp-slider"
            valueLabelDisplay="auto"
            min={-10}
            max={100}
          />
          <Typography>Low Temperature</Typography>
          <Slider
            value={lowTemp}
            onChange={handleLowTempChange}
            aria-labelledby="low-temp-slider"
            valueLabelDisplay="auto"
            min={-10}
            max={50}
          />
        </Box>
      </Popover>
      <Box
        component={motion.div}
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
        sx={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <motion.div
          animate={{
            scale: [1, 1.5, 1],
            opacity: [1, 0.5, 1],
          }}
          transition={{ duration: 1, ease: 'easeInOut', repeat: Infinity }}
          style={{
            width: '10px',
            height: '10px',
            backgroundColor: dotColor,
            borderRadius: '50%',
            marginRight: '8px',
          }}
        />
        <Typography variant="h6" fontWeight="bold">
          {status}
        </Typography>
      </Box>
      <Box
        component={motion.div}
        initial={{ scale: 0 }}
        animate={{ scale: [0, 1.2, 1] }}
        transition={{ duration: 0.8, ease: 'easeInOut' }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '10px',
        }}
      >
        <TemperatureIcon sx={{ fontSize: 50 }} />
      </Box>
      <Typography
        component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.3, ease: 'easeInOut' }}
        variant="h5"
        fontWeight="bold"
      >
        {sensorName}
      </Typography>
      <Typography
        component={motion.div}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, delay: 0.5, ease: 'easeInOut' }}
        variant="h1"
        fontWeight="bold"
      >
        {temperature}°C
      </Typography>
      <Box
        sx={{
          position: 'absolute',
          bottom: '10px',
          left: '10px',
        }}
      >
        <PersonIcon sx={{ fontSize: 24, color: '#ffffff' }} />
      </Box>
      <Box
         sx={{
          position: 'absolute',
          bottom: '10px',
          right: '10px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#00000040', // Semi-transparent black background
          padding: '1px',
          borderRadius: '8px',
        }}
      >
        <IconButton size="small" sx={{ color: '#ffffff', borderRadius: '12px' }} >
          
          <Typography variant="body2" sx={{ color: '#ffffff', ml: 1 }}>
            Download
          </Typography>
          <DownloadIcon />
        </IconButton>
        
        
      </Box>
    </Box>
  );
};

export default TemperatureBox;
