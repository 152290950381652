
//import ProgressCircle from "./ProgressCircle";

import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../theme';
import SensorStatus from '../sensor data/sensor-status'; // Assuming you have a SensorStatus component

const StatBox = ({title}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 30px"
    borderRadius={10}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h4" fontWeight="bold" sx={{ color: colors.grey[100] }}>
        {title}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" mt="2px">
        
        <SensorStatus title={title} />
      </Box>
    </Box>
  );
};

export default StatBox;








