// src/components/NotificationSidebar.js

import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, Divider, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import NotificationsIcon from "@mui/icons-material/Notifications";

const notifications = [
  "New user registered",
  "Server down for maintenance",
  "New comment on post",
  "You have a meeting at 3 PM",
];

const NotificationSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      display = 'flex'
      position="sticky"
      top={65}
      right={0}
      width="300px"
      height="90%"
      backgroundColor={colors.primary[400]}
      p="20px"
      
      boxShadow={3}
      borderRadius="20px"
      sx={{ overflowY: "auto" }}
    >
      <Box display="block" alignItems="center" mb="20px">
        <NotificationsIcon sx={{ color: colors.grey[100], fontSize: "28px", mr: "10px" }} />
        <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
          Notifications
        </Typography>
      </Box>
      <List display="block">
        {notifications.map((notification, index) => (
          <React.Fragment key={index}>
            <ListItem>
              <ListItemText primary={notification} primaryTypographyProps={{ color: colors.grey[100] }} />
            </ListItem>
            {index < notifications.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default NotificationSidebar;
