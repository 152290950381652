import { Box } from "@mui/material";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import React from 'react';
import '../line/index.css';

const ChildComponent = () => {
  return (
    <Box  width="50%">
      <Header title="Line Chart" subtitle="sensor 1" />
      <Box height={500} >
        <LineChart isDashboard={true} channelNumber="2449875" fieldNumber="1" />
      </Box>
    </Box>
  );
}


const Line = () => {
  const count = 5;

  return (
    <div className="line-div">
      {[...Array(count)].map((_, index) => (
        <ChildComponent key={index} />
      ))}
    </div>
  );
}



export default Line;
