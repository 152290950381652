import { Box } from "@mui/material";
import TemperatureBox from "../components/TemperatureBox"
import React from 'react';
import '../pages/Temp.css'

const ChildComponent = () => {
  return (
    <Box  width="25%"
    padding="20px"
    borderRadius='20px'>
      
      <Box height={400} >
      <TemperatureBox  sensorName={"Carne 1"} status={"Active"} />
      </Box>
    </Box>
  );
}


const TempBox = () => {
  const count = 5;

  return (
    <div className="Temp-div">
      {[...Array(count)].map((_, index) => (
        <ChildComponent key={index} />
      ))}
    </div>
  );
}



export default TempBox;