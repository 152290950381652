import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from './Main';
import Signup from "./loginsignup/src/components/Singup";
import Login from "./loginsignup/src/components/Login";
import PrivateRoute from './Routes/PrivateRoute';

function App() {
  return (
    
      <Routes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/*" element={<Main />} />
        </Route>
      </Routes>
  
  );
}

export default App;





