import React, { useState, useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';
import axios from 'axios';
import { parseISO } from 'date-fns';
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

const MultiLineChart = ({ channels }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allDataPromises = channels.map(async ({ channelNumber, fieldNumber }) => {
          const response = await axios.get(
            `https://api.thingspeak.com/channels/${channelNumber}/fields/${fieldNumber}.json?results=50`
          );

          const parsedData = response.data.feeds
            .map(feed => {
              const x = parseISO(feed.created_at);
              const y = parseFloat(feed[`field${fieldNumber}`]);

              if (isNaN(y)) {
                return null;
              }

              return { x, y };
            })
            .filter(d => d !== null)
            .sort((a, b) => new Date(a.x) - new Date(b.x));

          return {
            id: `Channel ${channelNumber} - Field ${fieldNumber}`,
            data: parsedData
          };
        });

        const allData = await Promise.all(allDataPromises);
        setData(allData);
        setLoading(false);
        setError(null);
      } catch (error) {
        console.error('Error fetching data', error);
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 5000);

    return () => clearInterval(interval);
  }, [channels]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!data.length) return <p>No data available</p>;

  return (
    <ResponsiveLine
      data={data}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
          },
        },
      }}
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: 'time', format: 'native', precision: 'minute' }}
      xFormat="time:%Y-%m-%d %H:%M:%S"
      yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Time',
        legendOffset: 36,
        legendPosition: 'middle',
        format: '%H:%M',
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Value',
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      enableGridX={true}
      enableGridY={false}
      colors={{ scheme: 'nivo' }}
      lineWidth={2}
      enablePoints={false}
      enableArea={true}
      areaBaselineValue={-15}
      areaOpacity={0.5}
      animate={true}
      motionConfig="wobbly"
      defs={[
        {
          id: 'gradientA',
          type: 'linearGradient',
          colors: [
            { offset: 0, color: 'inherit' },
            { offset: 0.78, color: 'rgba(0, 0, 0, 0)' },
          ],
        },
      ]}
      fill={[
        { match: '*', id: 'gradientA' },
      ]}
    />
  );
};

export default MultiLineChart;
