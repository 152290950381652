import { useState } from 'react';
import { Box, Button, Fab, IconButton, Modal, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import '../dashboard/index.css'
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddIcon from '@mui/icons-material/Add';
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import MultiLineChart from "../../components/MultiLineChart";
import StatBox from "../../components/StatBox";
import TempBox from "../../pages/Temp-Box"
//import ProgressCircle from "../../components/ProgressCircle";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const channels = [
      
    { channelNumber: '2449873', fieldNumber: '1' },
    { channelNumber: '2449874', fieldNumber: '1' },
    
    
];
const [open, setOpen] = useState(false);
const [sensorDetails, setSensorDetails] = useState({
  sensorName: '',
  sensorNumber: '',
  companyName: '',
  department: '',
  contactPerson: ''
});
const handleOpen = () => setOpen(true);
const handleClose = () => setOpen(false);

const handleChange = (e) => {
  const { name, value } = e.target;
  setSensorDetails((prevDetails) => ({
    ...prevDetails,
    [name]: value
  }));
};

const handleSubmit = async () => {
  try {
    const response = await fetch('http://localhost:8080/api/users/666c25d0d90a95a57d63a577/sensors', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(sensorDetails)
    });

    if (!response.ok) {
      // Check if response is not OK
      const errorData = await response.json();
      console.error('Failed to submit sensor details:', errorData);
      alert(`Failed to submit sensor details: ${errorData.message || response.statusText}`);
      return;
    }

    console.log('Sensor details submitted successfully');
    // Reset the form fields
    setSensorDetails({
      sensorName: '',
      sensorNumber: '',
      companyName: '',
      department: '',
      contactPerson: ''
    });
    handleClose();
  } catch (error) {
    console.error('Error during fetch:', error);
    alert('An error occurred while submitting sensor details. Please check the console for more details.');
  }
};

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="auto"
        gridTemplateRows={100}
        
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px"
          borderRadius={1.8}
        >
           <StatBox
            title="Active"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px"
          borderRadius={1.8}
        >
          <StatBox
            title="Warnings"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100px"
          borderRadius={1.8}
        >
          <StatBox
            title="Offline"
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius={1.8}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
              Temperature 
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                Data
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="450px" m="-20px 0 0 0">
            <MultiLineChart isDashboard={true} channels={channels}  />
          </Box>
        </Box>
        </Box>
  
      <div className="Box">
        {/* ROW 3 */}
        <TempBox/>
       </div>
       <Fab 
        sx={{ 
          position: 'fixed', 
          bottom: '20px', 
          right: '20px', 
          backgroundColor: colors.greenAccent[600],
          color: colors.grey[100],
          '&:hover': {
            backgroundColor: colors.greenAccent[700],
          }
        }} 
        aria-label="add" 
        onClick={handleOpen}
      >
        <AddIcon />
      </Fab>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          display="flex"
          flexDirection="column"
          position="absolute"
          top="20%"
          left="50%"
          transform="translate(-50%, -40%)"
          width={400}
          bgcolor="background.paper"
          borderRadius="8px"
          boxShadow={24}
          p={4}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Add Sensor Details
          </Typography>
          <TextField
            label="Sensor Name"
            name="sensorName"
            variant="outlined"
            margin="normal"
            fullWidth
            value={sensorDetails.sensorName}
            onChange={handleChange}
          />
          <TextField
            label="Sensor Number/ID"
            name="sensorNumber"
            variant="outlined"
            margin="normal"
            fullWidth
            value={sensorDetails.sensorNumber}
            onChange={handleChange}
          />
          <TextField
            label="Company Name"
            name="companyName"
            variant="outlined"
            margin="normal"
            fullWidth
            value={sensorDetails.companyName}
            onChange={handleChange}
          />
          <TextField
            label="Department"
            name="department"
            variant="outlined"
            margin="normal"
            fullWidth
            value={sensorDetails.department}
            onChange={handleChange}
          />
          <TextField
            label="Contact Person"
            name="contactPerson"
            variant="outlined"
            margin="normal"
            fullWidth
            value={sensorDetails.contactPerson}
            onChange={handleChange}
          />
          <Button
            variant="contained"
            sx={{ 
              mt: 2,
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              }
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </Modal>
      
      </Box>
     
  );
};

export default Dashboard;
